
























import { Vue, Component } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import BadgeCard from '@/components/Gamification/BadgeCard.vue'

import { useGamificationBadges } from '@/hooks/gamification'

@Component({
  components: { TheMainPage, TheHeader, BadgeCard },
  setup() {
    const { data: badgeData, isLoading: isBadgesloading } = useGamificationBadges()

    return {
      badgeData,
      isBadgesloading,
    }
  },
})
export default class Badges extends Vue {
  badgeData!: any

  async mounted() {}
}
