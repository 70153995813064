var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c("div", { staticClass: "flex justify-center f3 light-gray pv3" }, [
        _vm._v("Full List of Badges"),
      ]),
      _c("div", { staticClass: "flex justify-center" }, [
        _vm.isBadgesloading
          ? _c("div", {
              staticClass:
                "mt3 mh4 flex justify-center bg-adori-medium-gray mt1 br3 w-100 skeleton bottom-size",
            })
          : _c(
              "div",
              { attrs: { id: "container" } },
              _vm._l(_vm.badgeData, function (badge) {
                return _c("BadgeCard", {
                  key: badge.name,
                  attrs: {
                    title: badge.name,
                    content: badge.description,
                    points: badge.points,
                    imageUrl: badge.imageUrl,
                    isListing: true,
                  },
                })
              }),
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }